div[dir='rtl'] .label-floating {
  transform-origin: top right;
}

body.ar p,
body.ar span,
body.ar h1,
body.ar h2,
body.ar h3,
body.ar h4,
body.ar h5,
body.ar h6 {
  font-family: "Cairo", sans-serif !important;
  padding-bottom: 2px;
}

body.ar button {
  font-family: "Cairo", sans-serif !important;
}

body.ar .cardNumber label,
body.ar .expiry label,
body.ar .cvv label,
body.ar .cardHolder label,
body.ar .darkCardNumber label,
body.ar .darkExpiry label,
body.ar .darkCVV label,
body.ar .darkCardHolder label {
  font-family: "Cairo", sans-serif !important;
  font-size: smaller;
}

body.ar .otpForm {
  padding-inline-start: 30px;
}

html {
  scrollbar-color: rgb(102 102 102 / 50%) #f1f1f1
}

apple-pay-merchandising-modal {
  padding: unset !important;
}

.flex-grow {
  flex-grow: 1;
}

.react-tel-input {
  width: fit-content !important;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.hidden {
  display: none;
}

.center-vertical {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.otp-digit {
  background-color: transparent;
  text-align: center;
  border: 1px solid rgba(209, 213, 219, 1);
  border-radius: 0.375rem;
  height: 50px;
  width: 45px;
  margin: 5px;
  font-size: 16px;
  color: var(--ion-text-color);
}

ul.accordion {
  padding: 0;
  margin: 0;
}

ul.accordion li {
  list-style: none;
}

ul.accordion input[type='radio'] {
  display: none;
}

ul.accordion .content {
  display: none;
  margin: 5px;
}

/* Show content boxes when the radio buttons are checked */
ul.accordion input[type='radio']:checked+.content {
  display: block;
}

.text-align-center {
  align-items: center;
  align-self: center;
  align-content: center;
  text-align: center;
}


.wpwl-control {
  --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  --border-style: solid;
  --border-width: 1px;
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: #DDDDDD;
  background-color: var(--ion-background-color);
  border-radius: 5px;
  height: 45px;
  color: var(--ion-text-color);
}

.wpwl-brand-card-logo-highlighted::before {
  border: none;
}

div.wpwl-wrapper,
div.wpwl-label {
  width: 100%
}

div.wpwl-label:not(.wpwl-label-billing) {
  display: block;
  color: rgb(100, 100, 100);
  transform-origin: left top;
  transform: scale(1) translate3d(0px, 36px, 0px);
  font-size: 18px;
  transition: all 0.2s ease-out;
  z-index: 2;
  pointer-events: none;
  margin-top: 10px;
  margin-inline-start: 10px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wpwl-wrapper {
  position: static;
}

div[dir='rtl'] div.wpwl-label {
  transform-origin: right top;
}

div.wpwl-label.floating-label {
  transform: scale(0.8) translate3d(0px, 0px, 0px);
  color: #888888;
}

div.wpwl-label:not(.floating-label)+.wpwl-wrapper .wpwl-control-givenName::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}

div.wpwl-label:not(.floating-label)+.wpwl-wrapper .wpwl-control-givenName::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
}

div.wpwl-label:not(.floating-label)+.wpwl-wrapper .wpwl-control-givenName:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
}

div.wpwl-label:not(.floating-label)+.wpwl-wrapper .wpwl-control-surName {
  display: none;
}

div.wpwl-label:not(.floating-label)+.wpwl-wrapper .wpwl-control-givenName {
  width: 100%;
}

div[dir='rtl'] .wpwl-control-givenName {
  float: right;
  margin-right: 0;
  margin-left: 2%;
}

.wpwl-form-card {
  display: grid;
  grid-column-gap: 10px;
  grid-template-areas:
    "wpwl-group-cardNumber wpwl-group-cardNumber"
    "wpwl-group-cardHolder wpwl-group-cardHolder"
    "wpwl-group-expiry wpwl-group-cvv"
    "wpwl-group-billing wpwl-group-billing"
    "wpwl-group-save wpwl-group-save"
    "wpwl-group-submit wpwl-group-submit";
}

.wpwl-group-cardNumber {
  grid-area: wpwl-group-cardNumber;
}

.wpwl-group-brand {
  grid-area: wpwl-group-cardNumber;
}

.wpwl-group.wpwl-group-brand {
  margin-left: auto;
}

.wpwl-group-card-logos-horizontal {
  width: 100%;
  justify-content: flex-end;
  float: left;
}

.wpwl-brand-card {
  width: 54px;
}

.wpwl-group-submit {
  grid-area: wpwl-group-submit;
  align-content: center;
}

div[dir='rtl'] div.wpwl-group-save {
  transform-origin: right top;
}

.wpwl-group-save {
  grid-area: wpwl-group-save;
  align-content: center;
  display: inline-flex;
  padding-top: 15px;
  width: 90%;
}

.wpwl-group-expiry {
  grid-area: wpwl-group-expiry;
}

.save-label {
  margin-left: 10px;
}

.wpwl-group-cardHolder {
  grid-area: wpwl-group-cardHolder;
}

.wpwl-group-cvv {
  grid-area: wpwl-group-cvv;
}

.wpwl-group.wpwl-group-billing {
  margin-top: 25px;
  grid-area: wpwl-group-billing;
}

.wpwl-form-registrations .wpwl-group-billing {
  display: none;
}

.wpwl-form-registrations:not(.wpwl-form-has-inputs) .wpwl-button-pay {
  color: var(--ion-color-dark);
  background-color: transparent;
  border: none;
  margin-top: 0px;
  padding-top: 0px;
}

.wpwl-form-registrations:not(.wpwl-form-has-inputs) .wpwl-button-pay[disabled],
.wpwl-form-registrations:not(.wpwl-form-has-inputs) .wpwl-button-pay[disabled]:hover,
.wpwl-form-registrations:not(.wpwl-form-has-inputs) .wpwl-button-pay[disabled]:focus,
.wpwl-form-registrations:not(.wpwl-form-has-inputs) .wpwl-button-pay[disabled]:active,
.wpwl-form-registrations:not(.wpwl-form-has-inputs) .wpwl-button-pay:hover,
.wpwl-form-registrations:not(.wpwl-form-has-inputs) .wpwl-button-pay:active,
.wpwl-form-registrations:not(.wpwl-form-has-inputs) .wpwl-button-pay:focus {
  color: var(--ion-color-dark-shade);
  border: none;
  outline: none;
}

.wpwl-form-registrations:not(.wpwl-form-has-inputs) {
  margin-top: 0px;
}

.wpwl-wrapper-billing {
  display: grid;
  grid-column-gap: 10px;
  grid-template-areas:
    "wpwl-sup-wrapper-street1 wpwl-sup-wrapper-street1 wpwl-sup-wrapper-street1"
    "wpwl-sup-wrapper-city wpwl-sup-wrapper-state wpwl-sup-wrapper-postcode"
    "wpwl-sup-wrapper-country wpwl-sup-wrapper-country wpwl-sup-wrapper-country";
}

.wpwl-sup-wrapper-street1 {
  grid-area: wpwl-sup-wrapper-street1;
}

.wpwl-sup-wrapper-city {
  grid-area: wpwl-sup-wrapper-city;
}

.wpwl-sup-wrapper-state {
  grid-area: wpwl-sup-wrapper-state;
}

.wpwl-sup-wrapper-postcode {
  grid-area: wpwl-sup-wrapper-postcode;
}

.wpwl-sup-wrapper-country {
  grid-area: wpwl-sup-wrapper-country;
}

.wpwl-sup-wrapper-street2 {
  display: none;
}

div[dir='rtl'] .wpwl-button-pay {
  float: none;
}

.wpwl-wrapper-submit {
  display: flex;
  justify-content: center;
}

.wpwl-button-pay:hover {
  --background-hover-opacity: 0.8;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
}

.wpwl-button-pay[disabled],
.wpwl-button-pay[disabled]:hover,
.wpwl-button-pay[disabled]:focus,
.wpwl-button-pay[disabled]:active,
.wpwl-button-pay:hover,
.wpwl-button-pay:focus,
.wpwl-button-pay:active {
  color: var(--ion-color-light-shade);
  background-color: var(--ion-color-dark-shade);
}

.wpwl-group-registration.wpwl-selected {
  background-color: var(--ion-color-light-shade);
}

input[type=radio] {
  accent-color: var(--ion-color-dark);
}

.wpwl-button-pay {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
  border-color: var(--ion-item-border-color);
  border-radius: 4px;
  margin-top: 15px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  margin-top: 10px !important;
}

.wpwl-group {
  margin: 0px;
}

.dark {
  color-scheme: dark;
  --ion-text-color: white;
  --ion-background-color: #151f29 !important;
}

input.search-box {
  background-color: transparent;
  flex-grow: 1;
  margin-inline-end: 1rem;
}

ul.country-list li.search {
  display: flex;
  flex-direction: row;
  background-color: var(--ion-color-light) !important;
}

.react-tel-input .country-list {
  background-color: var(--ion-color-light) !important;
}

.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight {
  background-color: var(--ion-color-light-shade) !important;
}

.react-tel-input .form-control {
  background-color: transparent !important;
  color: var(--ion-text-color);
  width: unset !important;
}

.react-tel-input .selected-flag {
  background-color: transparent !important;
}

.react-tel-input .flag-dropdown {
  background-color: var(--color-background-shade) !important;
}

.react-tel-input {
  direction: ltr;
}

div[dir='rtl'] .country-list {
  direction: rtl;
}

.pac-container {
  width: unset !important;
  left: 16px !important;
  right: 16px !important;
}

.auth-code {
  width: 100%;

}

.mui-primary-button-light {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(30, 33, 42);
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  width: 100%;
}

.mui-primary-button-dark {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  width: 100%;
}

.itemsNumber {
  display: flex;
  gap: 5px;
}

[dir='rtl'] .itemsNumber {
  flex-direction: row-reverse;
}

#phone-number-input {
  direction: ltr !important;
  width: 100% !important;
}

#apple-pay-button {
  --apple-pay-button-width: 150px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}

#other-pay-button-inverted {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
}

@font-face {
  font-family: 'Cairo Regular';
  src: url('/public/assets/fonts/Cairo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Cairo Medium';
  src: url('/public/assets/fonts/Cairo-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Cairo SemiBold';
  src: url('/public/assets/fonts/Cairo-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Cairo Bold';
  src: url('/public/assets/fonts/Cairo-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Cairo ExtraBold';
  src: url('/public/assets/fonts/Cairo-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Cairo ExtraLight';
  src: url('/public/assets/fonts/Cairo-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Cairo Black';
  src: url('/public/assets/fonts/Cairo-Black.ttf') format('truetype');
}

.installments-setup::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.installments-setup {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.installments-setup .header {
  padding: 1% 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  overflow: hidden;
  color: var(--text-text-1, #1F1F1F);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 166.667% */
}

.installments-setup .description {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*align-self: stretch;*/
  border: 1px solid #B3B3B3;
  padding: 1% 3%;
  margin: 0 3%;
}

.installments-setup .description .title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 30px */
}

.installments-setup .description .body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .05em;
  width: 95%;

}

.installments-setup .description .list-point {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */

}

.installments-setup .banks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .05em;
  align-self: stretch;
  padding: 1% 3%;
}


.installments-setup .banks .banks-container .banks-item {
  border: 1px solid #B3B3B3;
  background: rgba(231, 231, 231, 0.40);
  ;
  display: flex;
  padding: 2%;
  align-items: center;
  gap: .05em;
  align-self: stretch;
}

.installments-setup .banks .banks-container .banks-item .bank-intro {
  display: flex;
  align-items: center;
}

.installments-setup .banks .banks-container .bank-intro .bank-image {
  min-width: 80px;
  width: 80px;
  height: 40px;
  margin: 0 5px;
}

.installments-setup .banks .banks-container .banks-item .bank-button {
  display: flex;
  align-items: center;
  min-width: 90px;
  width: 90px;
}

.installments-setup .banks .banks-container .banks-item .bank-intro .bank-description {
  padding: 0 5px;
}

/*.installments-setup .banks .banks-container .banks-item .bank-button .eligibility-button{*/
/*  float: left;*/
/*  left: 100%;*/
/*  width: 100%;*/
/*}*/

.installments-setup .notes {
  gap: .5em;
  color: #666;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  padding: 0 3%;
}


.installments-setup .footer .item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.installments-setup .footer .item .button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: rgba(231, 231, 231, 0.40);

  color: #041433;

  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 166.667% */
  margin: 16px;
  box-shadow: none;
}

.installment-eligibility-learn-more {
  display: flex;
  justify-content: flex-start;
}

.installment-eligibility-learn-more:hover {
  cursor: pointer;
}

.installment-eligibility-learn-more .learn-more-navigation {
  display: flex;
  font-size: 1.3rem;
  justify-content: center;
  align-content: space-around;
  align-items: stretch;
  flex-wrap: nowrap;
}

.installment-modal-span {
  border-radius: 24px;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  display: flex;
  max-width: 428px;
  padding-top: 20px;
  flex-direction: column;
}

.installment-modal-span-2 {
  justify-content: space-between;
  align-self: center;
  display: flex;
  gap: 10px;
  padding: 0 20px;
  width: 95%;
}

.installment-modal-img {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}

.installment-modal-div {
  overflow: hidden;
  color: var(--Text-Text-1, #1f1f1f);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 600 16px/30px Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.installment-modal-img-2 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.installment-modal-span-3 {
  align-self: center;
  border: 1px solid #cecece;
  display: flex;
  margin-top: 12px;
  width: 90%;
  max-width: 380px;
  flex-direction: column;
  padding: 17px 32px 17px 16px;
}

.installment-modal-div-2 {
  color: #000;
  font: 600 16px/24px Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.installment-modal-div-3 {
  color: #000;
  margin-top: 12px;
  font: 400 12px/18px Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.installment-modal-div-4 {
  color: #000;
  margin-top: 12px;
  font: 400 12px/150% Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.installment-modal-div-5 {
  color: #000;
  margin-top: 12px;
  font: 400 12px/18px Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.installment-modal-div-6 {
  align-self: center;
  border: 1px solid #cecece;
  background-color: #f4f4f4;
  display: flex;
  margin-top: 20px;
  width: 90%;
  max-width: 380px;
  justify-content: space-between;
  gap: 20px;
  padding: 12px 16px;
}

.installment-modal-span-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.installment-modal-img-3 {
  aspect-ratio: 2.53;
  object-fit: contain;
  object-position: center;
  width: 81px;
  mix-blend-mode: hard-light;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
}

.installment-modal-div-7 {
  color: #3c4043;
  align-self: stretch;
  font: 400 13px/20px Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
  display: flex;
  align-items: center;
}

.installment-modal-span-5 {
  justify-content: center;
  border-radius: 10px;
  background-color: #229e57;
  align-self: center;
  display: flex;
  gap: 4px;
  margin: auto 0;
  padding: 8px 13px;
}

.installment-modal-div-8 {
  color: #fff;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 14px/143% Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.installment-modal-img-4 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.installment-modal-div-9 {
  align-self: center;
  border: 1px solid #cecece;
  background-color: #f4f4f4;
  display: flex;
  margin-top: 20px;
  width: 90%;
  max-width: 380px;
  justify-content: space-between;
  gap: 20px;
  padding: 12px 16px;
}

.installment-modal-span-6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.installment-modal-img-5 {
  aspect-ratio: 2.53;
  object-fit: contain;
  object-position: center;
  width: 81px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
}

.installment-modal-div-10 {
  color: #3c4043;
  align-self: stretch;
  font: 400 13px/20px Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
  display: flex;
  align-items: center;
}

.installment-modal-span-7 {
  justify-content: center;
  border-radius: 10px;
  background-color: #229e57;
  align-self: center;
  display: flex;
  gap: 4px;
  margin: auto 0;
  padding: 8px 13px;
}

.installment-modal-div-11 {
  color: #fff;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 14px/143% Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.installment-modal-img-6 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.installment-modal-span-8 {
  align-self: center;
  border: 1px solid #cecece;
  background-color: #f4f4f4;
  display: flex;
  margin-top: 20px;
  width: 90%;
  max-width: 380px;
  justify-content: space-between;
  gap: 20px;
  padding: 12px 16px;
}

.installment-modal-img-7 {
  aspect-ratio: 2.09;
  object-fit: contain;
  object-position: center;
  width: 67px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.installment-modal-div-12 {
  color: #3c4043;
  font: 400 13px/20px Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
  display: flex;
  align-items: center;
}

.installment-modal-span-9 {
  justify-content: center;
  border-radius: 10px;
  background-color: #229e57;
  align-self: center;
  display: flex;
  gap: 4px;
  margin: auto 0;
  padding: 8px 13px;
}

.installment-modal-div-13 {
  color: #fff;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 14px/143% Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.installment-modal-img-8 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.installment-modal-div-14 {
  color: #666;
  margin-top: 20px;
  font: italic 500 14px/150% Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
  padding: 0 20px 20px 20px;
}

.installment-modal-div-15 {
  border-radius: 0px 0px 20px 20px;
  background-color: var(--_Surface-Old-Background, #fff);
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px 20px 24px;
}

.installment-modal-div-16 {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(231, 231, 231, 0.4);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 60px;
}

.cancel-rounded-icon:hover,
.installment-modal-div-16:hover,
.installment-modal-span-5:hover,
.installment-modal-span-7:hover,
.installment-modal-span-9:hover {
  cursor: pointer;
}

.installment-modal-span-10 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.installment-modal-img-9 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
}

.installment-modal-div-17 {
  color: #041433;
  text-align: center;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 18px/167% Inter, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.eligibility-span:hover,
.eligibility-span:active,
.eligibility-span:focus,
.eligibility-span:hover,
.eligibility-span {
  text-decoration: none;
  color: #fff;
}

.installment-modal-div-3 .circle-icon,
.installment-modal-div-4 .circle-icon,
.installment-modal-div-5 .circle-icon {
  justify-content: center;
  font-size: 5px !important;
  margin: 0 5px;
}

.installment-modal-div-3,
.installment-modal-div-4,
.installment-modal-div-5 {
  display: flex;
  align-items: baseline;
}

#payment-selector-tab-list {
  display: flex;
  border-radius: 10px;
}

#payment-selector-tab-list>div>div {
  display: flex;
  justify-content: space-around;
}

/*#bank-installment-selector-tab-list > div > div{*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*}*/

#payment-selector-tab-list>div>div>button,
#bank-installment-selector-tab-list>div>div>button {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  line-height: 130%;
}

#bank-installment-selector-tab-list>div>div>button {
  border: 2px solid rgb(213, 213, 213);
  border-radius: 10px;
}

#bank-installment-selector-tab-list>div>div>button:hover {
  border: 2px solid rgb(136, 136, 136);
}

#bank-installment-selector-tab-list>div>div>button:not(:first-child):not(:last-child) {
  margin-left: 12px;
  margin-right: 12px;
}

@media screen and (min-width: 375px) and (max-width: 425px) {
  #bank-installment-selector-tab-list>div>div>button {
    min-width: 80px;
  }
}

#bank-installment-plans thead tr th,
#bank-installment-plans tbody tr td {
  text-align: start;
  font-size: 0.75rem;
  line-height: 0.01;
  padding: 0.7rem 0;
}

.payment-link-enabled {
  background-image: url('../../public/background.jpg');
}

.payment-links-final {
  background-color: rgb(255, 255, 255);
  display: flex;
  border: 1px solid;
  margin: auto;
  margin-top: 5%;
  flex-direction: column;
  border-radius: 1rem;
  padding: 0 20px 20px 20px;
  overflow-y: hidden;
  justify-content: 'center';
  align-items: 'center';
}

.payment-links-final {
  background-color: rgba(23, 23, 23, 1);
  display: flex;
  border: 1px solid;
  margin: auto;
  margin-top: 5%;
  flex-direction: column;
  border-radius: 1rem;
  padding: 0 20px 20px 20px;
  overflow-y: hidden;
  justify-content: 'center';
  align-items: 'center';
}

.muirtl-1vjyynv-MuiPaper-root-MuiPopover-paper {
  width: 37vw !important;
}


@media screen and (max-width: 1320px) {
  .muirtl-1vjyynv-MuiPaper-root-MuiPopover-paper {
    width: 45vw !important;
  }
}

@media screen and (max-width: 924px) {
  .muirtl-1vjyynv-MuiPaper-root-MuiPopover-paper {
    width: 62vw !important;
  }
}

@media screen and (max-width: 769px) {
  .muirtl-1vjyynv-MuiPaper-root-MuiPopover-paper {
    width: 75vw !important;
  }
}

@media screen and (max-width: 600px) {
  .muirtl-1vjyynv-MuiPaper-root-MuiPopover-paper {
    width: 80vw !important;
  }
}